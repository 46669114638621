/* Import Cairo font from Google Fonts */
@import url("https://fonts.googleapis.com/css2?family=Cairo:wght@400;700&display=swap");

/* Wahran Font-Face */
@font-face {
  font-family: "Wahran";
  src: url("./assets/Wahran-Regular.otf") format("opentype"); /* Corrected format to 'opentype' */
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Wahran";
  src: url("./assets/ArbFONTS-Wahran-Bold.otf") format("opentype"); /* Corrected format to 'opentype' */
  font-weight: bold;
  font-style: normal;
}

/* Global Font Configuration with Fallback */
* {
  font-family: "Wahran", "Cairo", sans-serif !important;
}

a {
  text-decoration: none !important;
}

.rtl {
  direction: rtl;
  text-align: right;
}

.ltr {
  direction: ltr;
  text-align: left;
}

.my_profile-bg {
  width: 340px;
  border-top-left-radius: 15px;
  border-bottom-left-radius: 15px;
}

.my_profile_user-img {
  border-radius: 100%;
  margin-left: -64px;
}

.property_details-img {
  width: 100%;
}

@media screen and (max-width: 900px) {
  .my_profile-bg {
    width: 100%;
    border-radius: 15px;
  }

  .my_profile_user-img {
    margin-left: 0px;
    margin-top: -64px;
  }

  .property_details-img {
    width: 100%;
    height: auto;
  }
}
